import {Component, OnInit} from '@angular/core';
import {BasicProductDataStoreService} from '../../../core/basic-product-data-store.service';
import {Product} from '../../../core/data/product';
import { BasicProductsDataConstants } from '../BasicProductsDataConstants';
import { Tds } from 'src/app/core/data/tds';

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  isEditionMode = false;
  productsRow: any[] = [];
  optionsLengths: any[] =[];
  view: string = 'tds';
  // colors = ['#1f35de','#ffffff', '#f50707']

  readonly FILTERS_WIDTHS: string[] = BasicProductsDataConstants.FILTERS_WIDTHS;

  constructor(public basicProductDataStore: BasicProductDataStoreService) {
    this.isEditionMode = this.basicProductDataStore.isEditionMode;

    this.basicProductDataStore.products$.subscribe((products: Product[]) => {
      this.productsRow = [];
      // group by options
      for (const p of products) {
        this.productsRow.push({
          product: {
            connection: p.connection,
            od: p.od,
            wt: p.wt,
            weight: p.weight,
            grade: p.grade,
            lubricant: p.lubricant,
            option: p.option,
            product_type: p.productType,
            customer: p.customer,
            customer_information: p.customer_information,
            drawing: p.drawing
          },
          options: [p.option]

        });
      }
    });

    
  }

  ngOnInit(): void {
    this.basicProductDataStore.basicDataSelectedTab$.subscribe(value =>{
      this.view = value;
    });
    this.basicProductDataStore.surfaceTreatments$.subscribe((sts: any[]) => {
      if (sts.length)  {
        this.optionsLengths = sts.map(st => st.opt.length);
      }
    });
  }

  removeProduct(p: any): void {
    const productToRemove: Product = new Product();
    productToRemove.connection = p.product.connection;
    productToRemove.od = p.product.od;
    productToRemove.wt = p.product.wt;
    productToRemove.grade = p.product.grade;

    productToRemove.option = p.product.option;
    productToRemove.lubricant = p.product.lubricant;
    productToRemove.productType = p.product.product_type;
    productToRemove.customer = p.product.customer;
    this.basicProductDataStore.removeProduct(productToRemove);
  }
  splitData(data, name): string{
    let result = ""
    let i = 1;
    let splits = data.split('|');
    if  (splits.length == 1) return name + ' : ' + data;
    splits.forEach(d => {
      result = result + name + i + ' : ' + d + " \n";
      i++;
    });
    return result;
  }
}
