import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {Product} from './data/product';
import {ProductService} from './product.service';
import {ApplicabilityService} from './applicability.service';
import {BasicProductsDataConstants} from '../features/basic-product-data/BasicProductsDataConstants';
import {DataPage} from './data/data-page';
import {Tds} from './data/tds';
import {AssemblyData} from './data/assembly-data';
import {ThreadCompound} from './data/thread-compound';
import {BasicProductDataRestService} from './basic-product-data-rest.service';
import {map} from 'rxjs/operators';
import {DetailedTsliStore} from './detailed-tsli-store.service';

@Injectable({
  providedIn: 'root'
})
export class BasicProductDataStoreService {

  public readonly defaultOrder = [
    {name: 'connection', order: 'asc'},
    {name: 'od', order: 'asc'},
    {name: 'wt', order: 'asc'},
    {name: 'grade', order: 'asc'},
    {name: 'lubricant', order: 'asc'},
    {name: 'product_type', order: 'asc'},
    {name: 'option_name', order: 'asc'},
    {name: 'customer', order: 'asc'},
  ];

  public readonly assemblyDataTabs = ['Field', 'Mill'];
  public selectedAssemblyDataIndex = 0;
  public selectedSurfaceTreatmentIndex = 0;
  public readonly basicDataTabs = ['tds', 'assembly', 'threadCompound'];
  public readonly AssemblyValues = ['ASSEMBLYVALUES', 'MAXVALUES', 'OTHERASSEMBLY'];
  public selectedThreadCompoundHeaderIndex = 0;

  public readonly products = new BehaviorSubject<Product[]>([]);
  public readonly order = new BehaviorSubject<{ name: string, order: string }[]>(this.defaultOrder);
  public readonly surfaceTreatments = new BehaviorSubject<any>({});
  public readonly assemblyData = new BehaviorSubject<any>([]);
  public readonly threadCompound = new BehaviorSubject<any>([]);
  public readonly threadCompoundHeader = new BehaviorSubject<any>([]);

  public selectedIndextab = new BehaviorSubject<number>(0);
  readonly selectedIndextab$ = this.selectedIndextab.asObservable();
  public readonly currentFilter = new BehaviorSubject<any>({});
  public readonly currentSelection = new BehaviorSubject<any>({});
  public readonly productsOverallCount = new BehaviorSubject<number>(0);
  public readonly pageIndex = new BehaviorSubject<number>(0);
  public readonly pageSize = new BehaviorSubject<number>(BasicProductsDataConstants.DEFAULT_PAGE_SIZE);
  public readonly activatedDataReloading = new BehaviorSubject<boolean>(true);
  public readonly SelectedAssemblyValues = new BehaviorSubject<string>(this.AssemblyValues[0]);
  public readonly imperialOrSI = new BehaviorSubject<boolean>(false);
  public readonly octgOrNonOctg = new BehaviorSubject<boolean>(true);
  public readonly basicDataSelectedTab = new BehaviorSubject<string>(this.basicDataTabs[0]);

  readonly SelectedAssemblyValues$: Observable<string> = this.SelectedAssemblyValues.asObservable();
  readonly products$: Observable<Product[]> = this.products.asObservable();
  readonly order$: Observable<{ name: string, order: string }[]> = this.order.asObservable();
  readonly surfaceTreatments$: Observable<Tds[]> = this.surfaceTreatments.asObservable();
  readonly assemblyData$: Observable<AssemblyData[]> = this.assemblyData.asObservable();
  readonly threadCompound$: Observable<any[]> = this.threadCompound.asObservable();
  readonly threadCompoundHeader$: Observable<string[]> = this.threadCompoundHeader.asObservable();
  readonly currentFilter$: Observable<any> = this.currentFilter.asObservable();
  readonly currentSelection$: Observable<any> = this.currentSelection.asObservable();
  readonly productsOverallCount$: Observable<number> = this.productsOverallCount.asObservable();
  readonly pageIndex$: Observable<number> = this.pageIndex.asObservable();
  readonly pageSize$: Observable<number> = this.pageSize.asObservable();
  readonly activatedDataReloading$ = this.activatedDataReloading.asObservable();
  readonly imperialOrSI$ = this.imperialOrSI.asObservable();
  readonly octgOrNonOctg$ = this.octgOrNonOctg.asObservable();
  readonly basicDataSelectedTab$ = this.basicDataSelectedTab.asObservable();

  connection: string;
  isEditionMode: boolean;
  isPageDirty = true; // always fetch first page
  cachePageIndex = -1;
  drawing: string;
  customer_information: string;
  public cachedProducts: any[] = [];
  public cachedAssemblyData: any[] = [];
  public cachedThreadCompound: any[] = [];
  public cachedSurfaceTreatment: any[] = [];

  constructor(public productService: ProductService,
              public applicabilityService: ApplicabilityService,
              public bpdService: BasicProductDataRestService,
              public detailedTsliStore: DetailedTsliStore) {
  }

  addProduct(toAdd: Product): void {
    const currentProducts: Product[] = this.products.getValue();
    currentProducts.push(toAdd);
    console.log('Products: ', currentProducts);
    this.products.next(currentProducts);
    this.fetchBasicProductData(toAdd);
  }

  addProductOfTsliItem(toAdd: Product): Observable<Product> {
    const productToAdd: Product = new Product();
    productToAdd.connection = toAdd.connection;
    productToAdd.od = toAdd.od;
    productToAdd.wt = Number(('' + toAdd.wt).match('\\((.*)"')[1]);
    productToAdd.weight = Number(('' + toAdd.wt).match('(.*)#')[1]);
    productToAdd.grade = toAdd.grade;
    productToAdd.productType = toAdd['productType'];
    productToAdd.option = toAdd["option"];
    productToAdd.customer = toAdd.customer;
    productToAdd.lubricant = toAdd.lubricant;
    productToAdd.drawing = toAdd.drawing;
    productToAdd.customer_information = toAdd.customer_information;

    return this.productService.getNestedItems(productToAdd, this.octgOrNonOctg.value).pipe(map(p => {
      this.addProduct(p);
      return p;
    }));

  }

  addProductFromSelection(): void {
    const productToAdd: Product = new Product();
    const selection: any = this.getSelection();
    productToAdd.connection = selection[BasicProductsDataConstants.CONNECTION];
    productToAdd.od = selection[BasicProductsDataConstants.OD];
    productToAdd.wt = Number(selection[BasicProductsDataConstants.WT].match('\\((.*)"')[1]);
    productToAdd.weight = Number(selection[BasicProductsDataConstants.WT].match('(.*)#')[1]);
    productToAdd.grade = selection[BasicProductsDataConstants.GRADE];
    productToAdd.productType = selection[BasicProductsDataConstants.PRODUCT_TYPE];
    productToAdd.option = selection[BasicProductsDataConstants.DESIGN_OPTIONS];
    productToAdd.customer = selection[BasicProductsDataConstants.CUSTOMER];
    productToAdd.lubricant = selection[BasicProductsDataConstants.LUBRICANT];
    if (productToAdd.connection.includes('-SPECIFIC')) {
      productToAdd.drawing = this.currentFilter.getValue()['AllDesignOptions'].filter(v => v.value == productToAdd.option)[0]['drawing'];
      productToAdd.customer_information = this.currentFilter.getValue()['AllDesignOptions'].filter(v => v.value == productToAdd.option)[0]['customer_information'];
    }
    this.productService.getNestedItems(productToAdd, this.octgOrNonOctg.value).subscribe(p => {
      this.addProduct(p);
      this.detailedTsliStore.addProduct(p);
    });
    // this.addProduct(productToAdd);
  }

  removeProduct(toRemove: Product): void {
    const i: number = this.products.getValue().findIndex((p: Product) => {
      return p.connection === toRemove.connection
        && p.od === toRemove.od
        && p.wt === toRemove.wt
        && p.grade === toRemove.grade
        && p.lubricant === toRemove.lubricant
        && p.option === toRemove.option
        && p.customer === toRemove.customer
        && p.productType === toRemove.productType;
    });

    if (i === -1) {
      console.warn('Trying to remove an unknown product');
      console.warn(toRemove);
      console.warn(this.products.getValue());
      return;
    } else {
      const updatedProducts: Product[] = this.products.getValue();
      updatedProducts.splice(i, 1);
      const updatedTds: Tds[] = this.surfaceTreatments.getValue();
      updatedTds.splice(i, 1);
      this.cachedSurfaceTreatment.splice(i, 1);
      const updatedAssembly: AssemblyData[] = this.assemblyData.getValue();
      updatedAssembly.splice(i, 1);
      this.cachedAssemblyData.splice(i * 2, 2);
      const updatedThreadCompound: ThreadCompound[] = this.threadCompound.getValue();
      updatedThreadCompound.splice(i, 1);
      this.cachedThreadCompound.splice(i * this.threadCompoundHeader.value.length, this.threadCompoundHeader.value.length);

      this.products.next(updatedProducts);
      this.surfaceTreatments.next(updatedTds);
      this.assemblyData.next(updatedAssembly);
      this.threadCompound.next(updatedThreadCompound);
      this.detailedTsliStore.removeProduct(toRemove);
    }
  }

  clearProducts(): void {
    this.products.next([]);
    this.surfaceTreatments.next([]);
    this.assemblyData.next([]);
    this.cachedSurfaceTreatment = [];
    this.cachedAssemblyData = [];
    this.cachedThreadCompound = [];
    this.threadCompound.next([]);
  }

  loadFilter(filterName: string, parents: any[]): void {
    console.log('load filter');
    this.applicabilityService
      .getItemValues({item: filterName, parents: parents, fromFilter: true}, this.octgOrNonOctg.value)
      .subscribe(resp => {
        console.log('response filter:', resp);

        const currentFilterValue: any = this.currentFilter.getValue();
        if (resp.length > 0 && Object.keys(resp[0]).includes('value')) {
          currentFilterValue[filterName] = resp.map(r => r.value).slice();
          currentFilterValue['AllDesignOptions'] = resp.slice();
        } else {
          currentFilterValue[filterName] = resp;
        }

        console.log('currentFilterValue :', currentFilterValue);
        this.currentFilter.next(currentFilterValue);
      });
  }

  clearFilters(index: number) {
    const currentFilterValue: any = this.currentFilter.getValue();
    for (let i = index; i < BasicProductsDataConstants.FILTERS.length; i++) {
      const filterName = BasicProductsDataConstants.FILTERS[i];
      currentFilterValue[filterName] = [];
    }
    this.currentFilter.next(currentFilterValue);
  }

  setSelection(selection: any): void {
    this.currentSelection.next(selection);
  }

  getSelection(): any {
    return this.currentSelection.getValue();
  }

  clearSelection(): void {
    this.setSelection({});
  }

  fetchOverallCount(): void {
    this.applicabilityService.getProduct(this.octgOrNonOctg.value)
      .subscribe((response: number) => {
        this.productsOverallCount.next(response);
      });
  }

  fetchProductsPage(): void {
    if (!this.isPageDirty) {
      return;
    }
    this.deactivateDataReloading();
    const currentPageIndex: number = this.pageIndex.getValue();
    const currentPageSize: number = this.pageSize.getValue();
    const beginRow: number = currentPageIndex * currentPageSize;

    // -- first page is to be loaded, or page exceeds current cached content
    if (this.cachePageIndex === -1
      || beginRow >= (this.cachePageIndex * this.cachedProducts.length) + this.cachedProducts.length) {

      this.cachedProducts = [];
      this.cachePageIndex++;

      this.getProductsPage(beginRow, currentPageSize);

      // -- page is lower the current cached content
    } else if (beginRow < this.cachePageIndex * this.cachedProducts.length) {

      this.cachedProducts = [];
      this.cachePageIndex--;

      this.getProductsPage(beginRow, currentPageSize);

      // -- page is in the current cached content
    } else {
      const newProducts: Product[] = this.cachedProducts.slice(
        beginRow - (this.cachePageIndex * this.cachedProducts.length),
        beginRow - (this.cachePageIndex * this.cachedProducts.length) + currentPageSize);

      this.products.next(newProducts);
      // this.fetchSurfaceTreatmentPage();
      // this.fetchAssemblyData();
      // this.fetchThreadCompound();
      this.fetchBasicProductData();
    }

    this.isPageDirty = false;
  }

  fetchBasicProductData(product = undefined): void {
    let param: any = this.products.value;
    if (product != undefined) {
      param = product;
    } else {
      this.cachedSurfaceTreatment = [];
      this.cachedAssemblyData = [];
      this.cachedThreadCompound = [];
    }
    this.bpdService.getBasicProductData(param).subscribe(
      resp => {
        this.cachedSurfaceTreatment = this.cachedSurfaceTreatment.concat(resp['body'].tds);
        this.cachedAssemblyData = this.cachedAssemblyData.concat(resp['body'].assembly);
        this.cachedThreadCompound = this.cachedThreadCompound.concat(resp['body'].threadCompound);

        this.switchSurfaceTreatment(this.selectedSurfaceTreatmentIndex);
        this.activateDataReloading();

        if (this.selectedThreadCompoundHeaderIndex == -1) {
          this.threadCompound.next(this.cachedThreadCompound);
        } else {
          console.log("test")
          console.log(this.cachedThreadCompound)
          console.log(this.selectedThreadCompoundHeaderIndex)
          console.log(this.threadCompoundHeader)
          this.threadCompound.next(this.cachedThreadCompound.filter(row => row.name === this.threadCompoundHeader.value[this.selectedThreadCompoundHeaderIndex].name));
        }

        if (this.selectedAssemblyDataIndex == -1) {
          this.assemblyData.next(this.cachedAssemblyData);
        } else {
          this.assemblyData.next(this.cachedAssemblyData.filter(row => row.side === this.assemblyDataTabs[this.selectedAssemblyDataIndex]));
        }
      }
    );
  }

  getImperialOrSI() {
    return this.imperialOrSI.value;
  }

  fetchThreadCompoundHeaders(): void {
    let headerNames = [];
    this.bpdService.getThreadCompoundHeaders().subscribe(resp => {
      console.log('threadCompounds store:', resp);
      if (this.imperialOrSI.value) {
        resp.forEach(row => {
          headerNames.push({name: row.name, type: row.type, brand: row.brand, density: row.density});
        });
      } else {
        resp.forEach(row => {
          headerNames.push({name: row.name, type: row.type, brand: row.brand, density: row.density});
        });
      }

      this.threadCompoundHeader.next(headerNames);
    });

  }

  public getProductsPage(beginRow: number, currentPageSize: number): void {
    this.productService.getAllItems(this.octgOrNonOctg.value,
      this.cachePageIndex * 20,
      200,
      [{name: BasicProductsDataConstants.CONNECTION.toLowerCase(), value: this.connection}],
      this.order.getValue()
    ).subscribe((dataPage: DataPage) => {
      dataPage.data.forEach((dataPageEntry) => this.cachedProducts.push(this.normalizeProduct(dataPageEntry)));
      const newProducts: Product[] = this.cachedProducts.slice(
        beginRow - (this.cachePageIndex * this.cachedProducts.length),
        beginRow - (this.cachePageIndex * this.cachedProducts.length) + currentPageSize);
      this.products.next(newProducts);
      // this.fetchSurfaceTreatmentPage();
      // this.fetchAssemblyData();
      // this.fetchThreadCompound();
      this.fetchBasicProductData();
    });
  }

  setPageSize(value: number): void {
    if (value !== this.pageSize.getValue()) {
      this.pageSize.next(value);
      this.isPageDirty = true;
    }
  }

  setPageIndex(value: number): void {
    if (value !== this.pageIndex.getValue()) {
      this.pageIndex.next(value);
      this.isPageDirty = true;
    }
  }

  changeSortOrder(index) {
    this.order.getValue()[index].order = (this.order.getValue()[index].order === 'desc') ? 'asc' : 'desc';
    this.pageIndex.next(0);
    this.isPageDirty = true;
    this.cachePageIndex = -1;
    this.fetchProductsPage();
  }

  /*

    Normalize server-side objects (with customer, product_type)
    to Product type

   */
  public normalizeProduct(data: any): Product {
    const p: Product = new Product();
    p.connection = data.connection;
    p.od = data.od;
    p.wt = data.wt;
    p.weight = data.weight;
    p.option = data.option_name;
    p.productType = data.product_type;
    p.customer = data.customer;
    p.lubricant = data.lubricant;
    p.grade = data.grade;
    p.drawing = data.drawing;
    p.customer_information = data.customer_information;
    p.isolated = data.isolated;
    p.material = data.material;
    p.minwallthickness = data.minwallthickness;
    p.product = data.product;
    p.real_customer = data.real_customer;
    p.type_drift = data.type_drift;
    p.ymin_ksi = data.ymin_ksi;
    p.specific_requirement = data.specific_requirement;
    p.is_octg = data.is_octg;
    return p;
  }

  activateDataReloading() {
    this.activatedDataReloading.next(true);
  }

  deactivateDataReloading() {
    this.activatedDataReloading.next(false);
  }

  switchImperialOrSI() {
    this.imperialOrSI.next(!this.imperialOrSI.value);
    this.changeThreadCompoundHeaderDensity();

  }

  setImperialOrSI(siOrImp: boolean) {
    this.imperialOrSI.next(siOrImp);
    this.changeThreadCompoundHeaderDensity();

  }

  setOctgOrNonbOctg(isOCTG: boolean) {
    this.octgOrNonOctg.next(isOCTG);
  }

  switchOctgNonOctg() {
    this.octgOrNonOctg.next(!this.octgOrNonOctg.value);
    if (this.isEditionMode) {
      this.clearProducts();
      this.detailedTsliStore.clearProducts();
      this.currentSelection.next({});
      this.currentFilter.next({});
      this.loadFilter(BasicProductsDataConstants.CONNECTION, []);
    } else {
      this.pageIndex.next(0);
      this.isPageDirty = true;
      this.cachePageIndex = -1;
      this.fetchOverallCount();
      this.fetchProductsPage();
    }
  }

  switchSelectedAssemblyValues(index: any) {
    this.SelectedAssemblyValues.next(this.AssemblyValues[index]);
  }

  switchAssembyData(index: any) {
    this.selectedAssemblyDataIndex = index;
    if (index == -1) {
      this.assemblyData.next(this.cachedAssemblyData);
    } else {
      this.assemblyData.next(this.cachedAssemblyData.filter(row => row.side === this.assemblyDataTabs[index]));
    }
    this.SelectedAssemblyValues.next('ASSEMBLYVALUES');
    this.selectedIndextab.next(0);
  }

  switchSurfaceTreatment(index: any) {
    this.selectedSurfaceTreatmentIndex = index;
    if (index == -1) {
      this.surfaceTreatments.next(this.cachedSurfaceTreatment);
    } else {
      let tdsResp = [];
      this.cachedSurfaceTreatment.forEach(cTds => {
        let tdsRespOpt = [];
        cTds.options.forEach(cOpt => {
          tdsRespOpt.push({
            'comment': cOpt.comment,
            'end': index == 0 ? cOpt.fieldEnd : index == 1 ? cOpt.millEnd : cOpt.boxEnd
          });
        });
        tdsResp.push({'opt': tdsRespOpt});
      });
      this.surfaceTreatments.next(tdsResp);
    }
  }

  switchBasicDataTab(index: any) {
    this.selectedAssemblyDataIndex = 0;
    this.selectedThreadCompoundHeaderIndex = 0;
    this.selectedSurfaceTreatmentIndex = 0;

    this.basicDataSelectedTab.next(this.basicDataTabs[index]);
  }

  switchThreadCompound(index: any) {
    this.selectedThreadCompoundHeaderIndex = index;
    if (index == -1) {
      if (this.cachedThreadCompound != undefined) {
        this.threadCompound.next(this.cachedThreadCompound);
      }
    } else {
      if (this.cachedThreadCompound != undefined) {
        this.threadCompound.next(this.cachedThreadCompound.filter(row => row.name === this.threadCompoundHeader.value[index].name));
      }
    }
  }

  changeThreadCompoundHeaderDensity() {
    if (this.imperialOrSI.value) {
      this.threadCompoundHeader.next(this.threadCompoundHeader.value.map(item => {
        return {name: item.name, type: item.type, density: BasicProductsDataConstants.IMP_DENSITY[item.type]};
      }));
    } else {
      this.threadCompoundHeader.next(this.threadCompoundHeader.value.map(item => {
        return {name: item.name, type: item.type, density: BasicProductsDataConstants.SI_DENSITY[item.type]};
      }));
    }
  }

  generateDataAssemblyByItemToCsv(imperialOrsI: boolean, octg: string) {
    let fieldColumns;
    let millColmumns;
    if (!imperialOrsI) {
      fieldColumns = 'field_min_nm|field_opt_nm|field_max_nm|field_min_sht_nm|field_max_sht_nm|field_max_mts_nm|field_max_mtv_nm|' +
        'field_max_liner_nm|field_max_allowed_torque_nm|field_sot_mts_nm|';
      millColmumns = 'mill_min_nm|mill_opt_nm|mill_max_nm|mill_min_sht_nm|mill_max_sht_nm|mill_max_mts_nm|mill_max_mtv_nm|' +
        'mill_max_liner_nm|mill_max_allowed_torque_nm|mill_sot_mts_nm|';
    } else {
      fieldColumns = 'field_min_lbft|field_opt_lbft|field_max_lbft|field_min_sht_lbft|field_max_sht_lbft|field_max_mts_lbft|field_max_mtv_lbft|' +
        'field_max_liner_lbft|field_max_allowed_torque_lbft|field_sot_mts_lbft|';
      millColmumns = 'mill_min_lbft|mill_opt_lbft|mill_max_lbft|mill_min_sht_lbft|mill_max_sht_lbft|mill_max_mts_lbft|mill_max_mtv_lbft|' +
        'mill_max_liner_lbft|mill_max_allowed_torque_lbft|mill_sot_mts_lbft|';
    }
    let assembly_data_csv = '';
    if (this.cachedAssemblyData != null && this.cachedAssemblyData.length > 0) {
      assembly_data_csv += 'connection|od|wt|grade|lubricant|design_options|customer|product_type|' +
        fieldColumns +
        'field_ssf_min|field_ssf_max|field_dt_min|field_dt_max|field_jaw|' +
        millColmumns +
        'mill_ssf_min|mill_ssf_max|mill_dt_min|mill_dt_max|mill_jaw' + '\n';
      var i: number = 0;
      for (var j: number = 0; j < this.products.value.length; j++) {
        for (var k: number = i; k < i + 2; k++) {
          if (this.cachedAssemblyData[k].side.toUpperCase() == 'FIELD') {
            assembly_data_csv += this.products.value[j].connection + '|';
            assembly_data_csv += this.products.value[j].od + '|';
            assembly_data_csv += this.products.value[j].wt + '|';
            assembly_data_csv += this.products.value[j].grade + '|';
            assembly_data_csv += this.products.value[j].lubricant + '|';
            assembly_data_csv += this.products.value[j].option + '|';
            assembly_data_csv += this.products.value[j].customer + '|';
            assembly_data_csv += this.products.value[j].productType + '|';
            if (!imperialOrsI) {
              assembly_data_csv += this.cachedAssemblyData[k].minMakeUpTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].optMakeUpTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxMakeUpTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].minShoulderingTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxShoulderingTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxTorqueSealability + '|';
              assembly_data_csv += this.cachedAssemblyData[k].mtv + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxLinerTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxAllowedTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].shaleOperatingTorque + '|';
            } else {
              assembly_data_csv += this.cachedAssemblyData[k].minMakeUpTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].optMakeUpTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxMakeUpTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].minShoulderingTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxShoulderingTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxTorqueSealability_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].mtv_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxLinerTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxAllowedTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].shaleOperatingTorque_lbft + '|';
            }

            assembly_data_csv += this.cachedAssemblyData[k].minDeltaTurns + '|';
            assembly_data_csv += this.cachedAssemblyData[k].maxDeltaTurns + '|';
            assembly_data_csv += this.cachedAssemblyData[k].minShoulderSlope + '|';
            assembly_data_csv += this.cachedAssemblyData[k].maxShoulderSlope + '|';
            assembly_data_csv += this.cachedAssemblyData[k].jaws + '|';
          } else {
            if (!imperialOrsI) {
              assembly_data_csv += this.cachedAssemblyData[k].minMakeUpTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].optMakeUpTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxMakeUpTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].minShoulderingTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxShoulderingTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxTorqueSealability + '|';
              assembly_data_csv += this.cachedAssemblyData[k].mtv + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxLinerTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxAllowedTorque + '|';
              assembly_data_csv += this.cachedAssemblyData[k].shaleOperatingTorque + '|';
            } else {
              assembly_data_csv += this.cachedAssemblyData[k].minMakeUpTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].optMakeUpTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxMakeUpTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].minShoulderingTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxShoulderingTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxTorqueSealability_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].mtv_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxLinerTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].maxAllowedTorque_lbft + '|';
              assembly_data_csv += this.cachedAssemblyData[k].shaleOperatingTorque_lbft + '|';
            }
            assembly_data_csv += this.cachedAssemblyData[k].minDeltaTurns + '|';
            assembly_data_csv += this.cachedAssemblyData[k].maxDeltaTurns + '|';
            assembly_data_csv += this.cachedAssemblyData[k].minShoulderSlope + '|';
            assembly_data_csv += this.cachedAssemblyData[k].maxShoulderSlope + '|';
            assembly_data_csv += this.cachedAssemblyData[k].jaws;
            assembly_data_csv += '\n';
          }
        }

        i += 2;
      }
    }
    let fileName = 'ASSEMBLY_' + octg + '_' + (imperialOrsI ? 'IMPERIAL' : 'SI') + '.csv';
    this.download(fileName, assembly_data_csv);
  }

  generateDataThreadCompoundByItemToCsv(imperialOrsI: boolean, octg: string) {
    let thread_compound_data_csv = '';
    if (this.cachedThreadCompound != null && this.cachedThreadCompound.length > 0) {
      if (!imperialOrsI) {
        thread_compound_data_csv += 'connection|od|wt|grade|lubricant|lubricant_brand|lubricant_type|dope_validation|' +
          'dope_volume_min_si|dope_volume_max_si|dope_quantity_min_si|dope_quantity_max_si|' +
          'pin_box_ratio|comment' + '\n';
      } else {
        thread_compound_data_csv += 'connection|od|wt|grade|lubricant|lubricant_brand|lubricant_type|dope_validation|' +
          'dope_volume_min_imp|dope_volume_max_imp|dope_quantity_min_imp|dope_quantity_max_imp|' +
          'pin_box_ratio|comment' + '\n';
      }
      var i: number = 0;
      for (var j: number = 0; j < this.products.value.length; j++) {
        for (var k: number = i; k < i + 15; k++) {
          thread_compound_data_csv += this.products.value[j].connection + '|';
          thread_compound_data_csv += this.products.value[j].od + '|';
          thread_compound_data_csv += this.products.value[j].wt + '|';
          thread_compound_data_csv += this.products.value[j].grade + '|';
          thread_compound_data_csv += this.products.value[j].lubricant + '|';
          thread_compound_data_csv += this.cachedThreadCompound[k].name + '|';
          thread_compound_data_csv += this.cachedThreadCompound[k].tcValid + '|';
          if (!imperialOrsI) {
            thread_compound_data_csv += this.cachedThreadCompound[k].minDopeVolSI + '|';
            thread_compound_data_csv += this.cachedThreadCompound[k].maxDopeVolSI + '|';
            thread_compound_data_csv += this.cachedThreadCompound[k].minDopeQtySI + '|';
            thread_compound_data_csv += this.cachedThreadCompound[k].maxDopeQtySI + '|';
          } else {
            thread_compound_data_csv += this.cachedThreadCompound[k].minDopeVolIMP + '|';
            thread_compound_data_csv += this.cachedThreadCompound[k].maxDopeVolIMP + '|';
            thread_compound_data_csv += this.cachedThreadCompound[k].minDopeQtyIMP + '|';
            thread_compound_data_csv += this.cachedThreadCompound[k].maxDopeQtyIMP + '|';
          }
          thread_compound_data_csv += this.cachedThreadCompound[k].ratioPinBox + '|';
          thread_compound_data_csv += this.cachedThreadCompound[k].comment;
          thread_compound_data_csv += '\n';
        }
        i += 15;
      }
    }
    let fileName = 'THREAD_COMPOUND_' + octg + '_' + (imperialOrsI ? 'IMPERIAL' : 'SI') + '.csv';
    this.download(fileName, thread_compound_data_csv);
  }

  generateDataTdsByItemToCsv(octg: string) {
    let tds_data_csv: string;
    tds_data_csv = 'connection|od|wt|grade|design_options|customer|product_type|' +
      'pin field end as machined|pin field end Ceramic Bead Blasting|pin field end Alumina (Al2O3) Sand Blasting|pin field end Zinc Phosphate|pin field end Alumina (Al2O3) Sand Blasting + Zn Phosphate|pin field end Mn Phosphate|pin field end Alumina (Al2O3) Sand Blasting + Mn Phosphate|pin field end Copper Plating|' +
      'pin mill end as machined|pin mill end Ceramic Bead Blasting|pin mill end Alumina (Al2O3) Sand Blasting|pin mill end Zinc Phosphate|pin mill end Alumina (Al2O3) Sand Blasting + Zn Phosphate|pin mill end Mn Phosphate|pin mill end Alumina (Al2O3) Sand Blasting + Mn Phosphate|pin mill end Copper Plating|' +
      'box end as machined|box end Ceramic Bead Blasting|box end Alumina (Al2O3) Sand Blasting|box end Zinc Phosphate|box end Alumina (Al2O3) Sand Blasting + Zn Phosphate|box end Mn Phosphate|box end Alumina (Al2O3) Sand Blasting + Mn Phosphate|box end Copper Plating|box end Alumina (Al2O3) Sand Blasting + Copper Plating|Ceramic Bead Blasting + Copper Plating|box end Ternary Alloy|box end Piotec Plating|comment' + '\n';
    if (this.cachedSurfaceTreatment != null && this.cachedSurfaceTreatment.length > 0) {
      for (var i: number = 0; i < this.products.value.length; i++) {
        for (var j: number = 0; j < this.cachedSurfaceTreatment[i].options.length; j++) {
          tds_data_csv += this.products.value[i].connection + '|';
          tds_data_csv += this.products.value[i].od + '|';
          tds_data_csv += this.products.value[i].wt + '|';
          tds_data_csv += this.products.value[i].grade + '|';
          tds_data_csv += this.products.value[i].option + '|';
          tds_data_csv += this.products.value[i].customer + '|';
          tds_data_csv += this.products.value[i].productType + '|';
          tds_data_csv += this.getBinaryToHuman(this.cachedSurfaceTreatment[i].options[j].fieldEnd.join('|').split('-3').join('')) + '|';
          tds_data_csv += this.getBinaryToHuman(this.cachedSurfaceTreatment[i].options[j].millEnd.join('|').split('-3').join('')) + '|';
          tds_data_csv += this.getBinaryToHuman(this.cachedSurfaceTreatment[i].options[j].boxEnd.join('|').split('-3').join('')) + '|';
          tds_data_csv += this.cachedSurfaceTreatment[i].options[j].comment + '\n';
        }
      }
    }
    let fileName = 'TDS_' + octg + '.csv';
    this.download(fileName, tds_data_csv);
  }

  getBinaryToHuman(values: string): string {
    //pattern for "replaceAll"
    //str.split(search).join(replacement)
    //could have used str.replaceAll() here, but for Node and compatibility with older/non-current browsers
    values = values.split('0').join('No');
    values = values.split('1').join('Maybe');
    values = values.split('2').join('Yes');
    return values;
  }

  //function to download file
  download(fileName: string, content: string) {
    if (content !== '') {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  // fetchSurfaceTreatmentPage1(): void {
  //   this.cachedSurfaceTreatment = [];
  //   let results = [];
  //   this.surfaceTreatments.next([]);
  //   if (this.products.value.length > 0) {
  //     this.tdsService.getTDS2(this.products.value).subscribe(resp => {
  //       resp.forEach(row => {
  //         this.cachedSurfaceTreatment = this.cachedSurfaceTreatment.concat(row);
  //       });
  //       this.switchSurfaceTreatment(this.selectedSurfaceTreatmentIndex);
  //       this.activateDataReloading();
  //     });
  //   }
  //   else {
  //     this.activateDataReloading();
  //   }
  // }

  // fetchAssemblyData1(): void {
  //   this.cachedAssemblyData = [];
  //   this.assemblyData.next([]);
  //   if (this.products.value.length > 0) {
  //     this.assemblyDataService.getAssemblyData2(this.products.value).subscribe(resp => {
  //       resp.forEach(row => {
  //         this.cachedAssemblyData = this.cachedAssemblyData.concat(row);
  //       });
  //       if(this.selectedAssemblyDataIndex == -1)
  //       this.assemblyData.next(this.cachedAssemblyData);
  //       else
  //         this.assemblyData.next(this.cachedAssemblyData.filter(row => row.side === this.assemblyDataTabs[this.selectedAssemblyDataIndex]));
  //     });
  //   }
  // }

  // fetchThreadCompound1(): void {
  //   this.cachedThreadCompound = [];
  //   this.threadCompound.next([]);
  //   if (this.products.value.length > 0) {
  //     this.threadCompoundService.getThreadCompound2(this.products.value).subscribe(
  //       resp => {
  //         resp.forEach(row => {
  //           this.cachedThreadCompound = this.cachedThreadCompound.concat(row);
  //         });
  //       // this.cachedThreadCompound = threadValues;
  //       if(this.selectedThreadCompoundHeaderIndex == -1)
  //         this.threadCompound.next(this.cachedThreadCompound);
  //       else
  //         this.threadCompound.next(this.cachedThreadCompound.filter(row => row.name === this.threadCompoundHeader.value[this.selectedThreadCompoundHeaderIndex].name));
  //     });
  //   }
  // }
}
